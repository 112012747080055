import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
const toastr = require("toastr");
import "jquery-mask-plugin";

import { default as dark_mode } from './utils/dark_mode.js'

const Utils =  function($) {
  $(document).on("ready page:load turbolinks:load", function() {
    $.fn.select2.defaults.set("theme", "bootstrap4");
    $.fn.select2.defaults.set("language", "pt-BR");

    $('.currency').mask('000.000.000.000.000,00', {reverse: true})
    $('.phone').mask('(00) 0000-00009')
    $('.cpf').mask('000.000.000-00', {reverse: true})
    $('.cnpj').mask('00.000.000/0000-00', {reverse: true})

    dark_mode.init();

    let options = {
      onKeyPress: (value, e, field, options) => {
        masks = ['000.000.000-00999', '00.000.000/0000-00'];
        mask = masks[(value.length > 14 ? 1 : 0)];
        $('.cpf_cnpj').mask(mask, options);
      }
    };
    $('.cpf_cnpj').mask('000.000.000-00999', options);


	$("#mail_body_sucess .trix-button-group-spacer").each(function(){
		this.innerHTML = "Nome = #NAME# | Usuáiro = #USER# | Senha = #PASSWORD#" ;
	});

	$("#mail_body_waiting_payment .trix-button-group-spacer").each(function(){
		this.innerHTML = "Nome = #NAME# | Link Boleto/PIX = #CHECKOUT_URL#" ;
	});

    $("select.select2").each(function(){
      var select2_field = $(this);
      if (select2_field.data('url')){
        // select2_field.select2({theme: "bootstrap4",language: "pt-BR"})
        window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
      } else {
        select2_field.select2()
      }
    })

    let cepOptions =  {
      onComplete: (cep, event, currentField) =>{

        let card = currentField.parents('.card')

        if (cep.length > 6){
          window.cep(cep).then( (value) => {
            card.find("[name$='[city]']").val(value.city)
            card.find("[name$='[address]']").val(value.street)
            card.find("[name$='[district]']").val(value.neighborhood)
            card.find("[name$='[state]']").val(value.state)
          }).catch(
            console.log('CEP não encontrado ou serviço indisponível.')
            // toastr.error('CEP não encontrado ou serviço indisponível.', 'Erro!')
          )
        }


      }
    }

    $('input.cep').unmask().mask('00000-000',cepOptions)

    flatpickr("input.date_picker", {dateFormat: 'd/m/Y', "locale": Portuguese })
    flatpickr("input.time_picker", {"locale": Portuguese, time_24hr: true, enableTime: true, noCalendar: true})
    flatpickr("input.date_time_picker", {"locale": Portuguese, time_24hr: true, enableTime: true})

    let now = new Date
    flatpickr("input.month_picker", {"locale": Portuguese,
      minDate: now.getFullYear()+'-'+(now.getMonth()+1),
      plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: "m/Y", //defaults to "F Y"
          altFormat: "m/Y", //defaults to "F Y"
          theme: "light" // defaults to "light"
        })
      ]
    })


    toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toast-bottom-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "500",
      "hideDuration": "1000",
      "timeOut": "10000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }

    const showToastType = {
      notice: 'success',
      success: 'success',
      alert: 'error',
      error: 'error',
      warning: 'warning',
      info: 'info'
    };

    const showToastOptions = {
      notice: {},
      alert: { "timeOut": "0", "extendedTimeOut": "0" },
      warning: { "timeOut": "0", "extendedTimeOut": "0" },
      info: {}
    };

    window.showToast = function(messages){
      for(var i = 0; i < messages.length; i++ ){
        var msg = messages[i];
        try {
          toastr[showToastType[msg[0]]](msg[1], '', showToastOptions[msg[0]]);
        } catch(err) {
          toastr.info(msg[1], '', showToastOptions[msg[0]]);
        }
      }
    };

    $('#phone_number').on('cocoon:before-insert', function(e,task_to_be_added) {
      task_to_be_added.find('input.phone').mask('(00) 0000-00009')
      task_to_be_added.find('select.select2').select2()
    })

    $('#addresses').on('cocoon:before-insert', function(e,task_to_be_added) {
      task_to_be_added.find('input.cep').mask('00000-000',cepOptions)
      task_to_be_added.find('select.select2').select2()
    })

	$('#program_exercise').on('cocoon:before-insert', function(e,task_to_be_added) {
	  task_to_be_added.find('select.select2').each(function(){
		var select2_field = $(this);
		if (select2_field.data('url')){
		  window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
		} else {
		  select2_field.select2()
		}
	  })
	})

	$('#combo_strategies').on('cocoon:before-insert', function(e,task_to_be_added) {
	  task_to_be_added.find('select.select2').each(function(){
		var select2_field = $(this);
		if (select2_field.data('url')){
		  window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
		} else {
		  select2_field.select2()
		}
	  })
	})

	$('#combo_programs').on('cocoon:before-insert', function(e,task_to_be_added) {
	  task_to_be_added.find('select.select2').each(function(){
		var select2_field = $(this);
		if (select2_field.data('url')){
		  window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
		} else {
		  select2_field.select2()
		}
	  })
	})
  
  $('#combo_programs_blockeds').on('cocoon:before-insert', function(e,task_to_be_added) {
	  task_to_be_added.find('select.select2').each(function(){
		var select2_field = $(this);
		if (select2_field.data('url')){
		  window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
		} else {
		  select2_field.select2()
		}
	  })
	})

	$('#combo_techniques').on('cocoon:before-insert', function(e,task_to_be_added) {
	  task_to_be_added.find('select.select2').each(function(){
		var select2_field = $(this);
		if (select2_field.data('url')){
		  window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
		} else {
		  select2_field.select2()
		}
	  })
	})

    

	$('#person_combo').on('cocoon:before-insert', function(e,task_to_be_added) {
	  task_to_be_added.find('select.select2').each(function(){
		var select2_field = $(this);
		if (select2_field.data('url')){
		  window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
		} else {
		  select2_field.select2()
		}
	  })
	})

	$('#strategy_days').on('change', function(e, task_to_be_added){
		var days = e.target.value * 1;
		var current_days_form = $("#days .strategy-days-form")
		if (days > 7)
			days = 7;
		if (days < 0)
			days = 0;

		if (current_days_form.length == days)
			return;



		var current_length = current_days_form.length;
		while (current_length < days) {
			$("#add_day_button").trigger("click");
			current_length++;
		}

		while (current_days_form.length > days) {
			$(".rm_day_button").last().trigger("click");
			days ++;
		}

		var fields_day = $("#days .strategy-days-form .input-day");
		var counDayOff = 4 - 4 / days;
		fields_day.each((index, obj) => {
			if (fields_day.length <= 2) {
				obj.value = Math.pow(index + 1,2);
			} else {
				obj.value = (index + 1);
				if (((fields_day.length == 3) || (fields_day.length == 4)) && index > 1)
					obj.value = (index + 2);
			}
		});

	})

	$('#ex_days').on('cocoon:before-insert', function(e,task_to_be_added) {
	  task_to_be_added.find('select.select2').each(function(){
  		var select2_field = $(this);
  		if (select2_field.data('url')){
  		  window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
  		} else {
  		  select2_field.select2()
  		}
	  })
	})

	$('#days').on('cocoon:before-insert', function(e,task_to_be_added) {
	  task_to_be_added.find('select.select2').each(function(){
  		var select2_field = $(this);
  		if (select2_field.data('url')){
  		  window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
  		} else {
  		  select2_field.select2()
  		}
	  })
	})


  });
}

export default Utils;
